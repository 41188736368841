<template>
  <div class="layout-container">
    <header class="header">
      <nav class="navbar navbar-expand-lg navbar-light bg-white">
        <div class="container">
          <a class="navbar-brand">
            <img
              src="@/assets/Logo/Skyfarm_solutions.png"
              alt="SkyFarm Solutions Logo"
              class="logo"
            />
          </a>
          <!-- Navbar Right Content, including newsletter signup and toggler button -->
          <div class="d-flex align-items-center">
            <!-- Navbar Toggler -->
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>

          <!-- Collapsible Content -->
          <div
            class="collapse navbar-collapse justify-content-center"
            id="navbarNav"
          >
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :class="{ active: $route.path === '/' }"
                  to="/"
                  >Home</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :class="{ active: $route.path === '/about' }"
                  to="/about"
                  >About Us</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :class="{ active: $route.path === '/services' }"
                  to="/services"
                  >Services</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :class="{ active: $route.path === '/contact' }"
                  to="/contact"
                  >Contact</router-link
                >
              </li>
            </ul>
          </div>
          <!-- Newsletter Signup Form -->
          <form class="newsletter-signup d-none d-lg-flex">
            <!-- Hide on mobile -->
            <label for="email">Sign up</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your email"
            />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </nav>
    </header>

    <div class="content">
      <router-view />
    </div>

    <footer class="footer">
      <div class="container">
        <div class="footer-info">
          <p>
            Contact us at:
            <a href="mailto:info@skyfarmsolutions.com"
              >skyyfarmsolutions@gmail.com</a
            >
          </p>
          <p>
            Follow us on:
            <a href="https://facebook.com/skyfarmsolutions">Facebook</a> |
            <a href="https://twitter.com/skyfarmsolutions">Twitter</a> |
            <a href="https://linkedin.com/company/skyfarmsolutions">LinkedIn</a>
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "LayoutComponent",
  methods: {
    collapseNavbar() {
      const navbar = document.getElementById("navbarNav");
      if (navbar.classList.contains("show")) {
        navbar.classList.remove("show");
      }
    },
  },
  mounted() {
    // Close navbar when clicking outside
    document.addEventListener("click", (event) => {
      const navbar = document.getElementById("navbarNav");
      const toggler = document.querySelector(".navbar-toggler");
      if (
        navbar.classList.contains("show") &&
        !navbar.contains(event.target) &&
        !toggler.contains(event.target)
      ) {
        navbar.classList.remove("show");
      }
    });
  },
};
</script>

<style scoped>
/* Your existing CSS */

/* Hide the newsletter signup form on smaller screens */
@media (max-width: 991px) {
  .newsletter-signup {
    display: none !important;
  }
  /* White background for nav items in mobile view */
  .navbar-nav {
    background-color: white;
    border-top: 1px solid #ddd;
  }
}

/* Tablet view: Adjust navbar toggler placement */
@media (max-width: 767px) {
  .navbar-toggler {
    margin-left: auto; /* Pushes the toggler to the right */
  }

  /* Mobile view: Adjust navbar height and background */
  .navbar {
    height: 100px; /* Increased by 5px from the original 90px */
  }

  /* White background for nav items in mobile view */
  .navbar-nav {
    background-color: white;
    border-top: 1px solid #ddd;
  }

  /* Ensure nav links also take the full width and align center in mobile */
  .nav-link {
    display: block;
    padding: 10px;
    text-align: center;
    width: 100%;
  }

  /* Optionally, change color of nav links in mobile */
  .nav-link {
    color: black;
  }

  .nav-link:hover {
    background-color: #f8f9fa; /* Light grey on hover for better UX */
  }
}

/* Navbar/Header*/
.header {
  position: sticky;
  top: 0;
  width: 100%;
  height: 104px;
  z-index: 1000; /* Ensure the navbar stays above other content */
  background-color: white; /* Ensure the background color */
  border-bottom: 1px solid #ddd; /* Optional: adds a subtle border */
}

.logo {
  height: 90px; /* Adjust the size as needed */
}

.navbar {
  height: 90px; /* Standard height for the navbar */
}

.navbar-toggler {
  border: none; /* Optional: remove the default border */
}

.nav-link {
  font-size: 16px; /* Adjust font size as needed */
  color: black; /* Default text color */
  padding: 10px 15px; /* Adjust padding as needed */
}

.nav-link.active {
  color: #04ae34; /* Green color for active route */
  border-bottom: 2px solid #04ae34; /* Green border-bottom for active route */
}

.nav-link:hover {
  background-color: #f8f9fa; /* Light grey on hover for better UX */
}

.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full height to make sure footer sticks at the bottom */
}

.content {
  flex: 1; /* Allows the content to grow and push the footer down */
}

.footer {
  background-color: #333;
  color: white;
  padding: 20px;
  margin-top: auto; /* Pushes the footer to the bottom */
}

.footer-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.footer-info p {
  margin: 0;
  padding: 0;
}

.footer-info a {
  color: #04ae34; /* Green color to match the brand */
  text-decoration: none;
}

.footer-info a:hover {
  text-decoration: underline;
}

.newsletter-signup {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.newsletter-signup label {
  margin-right: 10px;
}

.newsletter-signup input {
  margin-right: 10px;
  padding: 5px;
}

.newsletter-signup button {
  background-color: #04ae34; /* Green color for the button */
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
}

.newsletter-signup button:hover {
  background-color: #037d24; /* Darker green on hover */
}
</style>
