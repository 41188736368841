<template>
  <div>
    <!-- Hero Banner -->
    <section class="hero-banner">
      <div class="hero-slideshow">
        <img
          v-for="(image, index) in images"
          :key="index"
          :src="image"
          :alt="'Hero Image ' + (index + 1)"
          class="hero-image"
          :class="{ active: index === currentImageIndex }"
        />
        <div class="hero-overlay"></div>
      </div>
      <div class="hero-content">
        <h1 class="tagline">Precision Agriculture for a Sustainable Future</h1>
        <div class="cta-buttons">
          <router-link
            class="btn"
            style="background-color: #04ae34; color: white"
            to="/services"
            >View Our Services</router-link
          >
          <router-link class="btn btn-secondary" to="/contact"
            >Contact Us Today</router-link
          >
        </div>
      </div>
    </section>

    <!-- Overview Section -->
    <section class="overview">
      <div class="container">
        <h2>About Us</h2>
        <p>
          SkyFarm Solutions is a leading provider of precision agriculture
          services in South Africa. Using advanced drone technology and
          multispectral data, we help small-scale and medium-scale farmers
          optimize their farming practices and increase their yields.
        </p>
        <p>
          We offer services like crop health monitoring, soil analysis,
          irrigation management, and targeted fertilizer application.
        </p>
      </div>
    </section>

    <!-- Benefits Section -->
    <section class="benefits">
      <div class="container">
        <h2>Why Choose Us?</h2>
        <div class="benefit-grid">
          <div class="benefit-item">
            <img
              src="@/assets/icons/growth.png"
              title="yield icons"
              class="benefit-icon"
            />
            <h3>Increased Yields</h3>
            <p>Maximize crop output through precise farming techniques.</p>
          </div>
          <div class="benefit-item">
            <img
              src="@/assets/icons/cost-effectiveness.png"
              alt="Cost Efficiency"
              class="benefit-icon"
            />
            <h3>Cost Efficiency</h3>
            <p>Reduce waste and save on water and fertilizers.</p>
          </div>
          <div class="benefit-item">
            <img
              src="@/assets/icons/planet-earth.png"
              alt="Sustainable Practices"
              class="benefit-icon"
            />
            <h3>Sustainable Practices</h3>
            <p>Promote environmentally friendly farming.</p>
          </div>
          <div class="benefit-item">
            <img
              src="@/assets/icons/analysis.png"
              alt="Data-Driven Insights"
              class="benefit-icon"
            />
            <h3>Data-Driven Insights</h3>
            <p>Make informed decisions based on accurate data.</p>
          </div>
        </div>
      </div>
    </section>
    <!-- Client Testimonials -->
    <section class="testimonials">
      <div class="container">
        <h2>What Our Clients Say</h2>
        <blockquote>
          <p>
            "SkyFarm Solutions has revolutionized our farming practices. The
            data insights and precision application have significantly increased
            our crop yields."
          </p>
          <footer>— Nandile Rini, Farmer</footer>
        </blockquote>
        <!-- Add more testimonials as needed -->
      </div>
    </section>

    <!-- Featured Case Study -->
    <!-- <section class="case-study">
      <div class="container">
        <h2>Case Study: Successful Crop Health Improvement</h2>
        <p>
          In a recent project, we used our drones and data analytics to identify
          and address crop health issues, resulting in a substantial increase in
          yield. The targeted approach allowed for precise interventions and
          optimized resource use.
        </p>
      </div>
    </section> -->
  </div>
</template>

<script>
export default {
  name: "HomeComponent",
  data() {
    return {
      activeRoute: "home", // Default active route
      images: [
        require("@/assets/Logo/home.jpg"),
        require("@/assets/Logo/home2.jpg"),
        require("@/assets/Logo/home5.jpg"),
      ],
      currentImageIndex: 0,
      interval: null,
    };
  },
  mounted() {
    this.startSlideshow();
  },
  beforeUnmount() {
    // Updated lifecycle hook
    this.stopSlideshow();
  },
  methods: {
    setActiveRoute(route) {
      this.activeRoute = route; // Set the active route based on the clicked link
      this.$emit("updatePage", route); // Emit event to update currentPage on App.vue
    },
    startSlideshow() {
      this.interval = setInterval(() => {
        this.currentImageIndex =
          (this.currentImageIndex + 1) % this.images.length;
      }, 4000); // Change image every 4 seconds
    },
    stopSlideshow() {
      clearInterval(this.interval);
    },
  },
};
</script>

<style scoped>
/* Hero Banner Styles */
.hero-banner {
  position: relative;
  text-align: center;
  color: white;
  overflow: hidden;
}

.hero-slideshow {
  position: relative;
  width: 100%;
  height: 85vh; /* Maintain aspect ratio for slideshow container */
  overflow: hidden;
}

.hero-image {
  width: 100%;
  height: 100%; /* Use 100% to fill the hero-slideshow container */
  object-fit: cover; /* Ensures image covers the container without stretching */
  object-position: center; /* Centers the image within the container */
  transition: opacity 0.5s ease; /* Smooth transition for the slideshow */
  position: absolute; /* Position absolute for stacking */
  top: 0;
  left: 0;
  opacity: 0; /* Initially hidden */
}

.hero-image.active {
  opacity: 1;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.327),
    rgba(0, 0, 0, 0.318)
  );
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; /* Ensure content is above the images */
}

.tagline {
  font-size: 2.5em;
  margin-bottom: 20px;
}

/* CTA Buttons */
.cta-buttons .btn {
  margin: 10px;
}

@media (max-width: 768px) {
  .hero-slideshow {
    height: 60vh; /* Adjust height for tablets */
  }
  .tagline {
    font-size: 1.8em; /* Adjust font size for tablets */
  }
}

@media (max-width: 480px) {
  .hero-slideshow {
    height: 50vh; /* Further decrease height for mobile phones */
  }
  .tagline {
    font-size: 1.5em; /* Further reduce font size for mobile phones */
  }
  .cta-buttons .btn {
    padding: 6px 12px; /* Adjust button padding for mobile phones */
  }
}

.overview,
.benefits,
.testimonials,
.case-study,
.footer {
  padding: 20px;
  text-align: center;
}
.benefits {
  padding: 20px;
  text-align: center;
}

.benefit-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.benefit-item {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.benefit-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}
.testimonials blockquote {
  font-style: italic;
}
.footer {
  background-color: #333; /* Dark color for footer */
  color: white; /* Text color to contrast with the dark background */
  padding: 20px;
}

.footer-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.footer-info p {
  margin: 0;
  padding: 0;
}

.footer-info a {
  color: #04ae34; /* Green color to match the brand */
  text-decoration: none;
}

.footer-info a:hover {
  text-decoration: underline;
}

.newsletter-signup {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.newsletter-signup label {
  margin-right: 10px;
}

.newsletter-signup input {
  margin-right: 10px;
  padding: 5px;
}

.newsletter-signup button {
  background-color: #04ae34; /* Green color for the button */
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
}

.newsletter-signup button:hover {
  background-color: #037d24; /* Darker green on hover */
}
</style>
