<template>
  <div class="about">
    <!-- Our Story Section with Background Image -->
    <section class="our-story">
      <div class="content">
        <h1>Our Story</h1>
        <p>
          Founded in 2024, SkyFarm Solutions is committed to transforming
          agriculture in South Africa and beyond through the power of
          technology. Our mission is to empower farmers with the tools and
          insights they need to achieve greater productivity and sustainability.
        </p>
      </div>
    </section>

    <section class="technology">
      <h2>Technology</h2>
      <div class="technology-cards">
        <!-- Our Drones Subsection -->
        <div class="technology-card">
          <h3>Our Drones</h3>
          <p>
            We use state-of-the-art drones, including the DJI Mavic 3
            Multispectral for crop monitoring and sprayer drones for targeted
            fertilizer application. These drones are equipped with the latest
            technology to ensure accurate data collection and application.
          </p>
        </div>

        <!-- Data Analytics Subsection -->
        <div class="technology-card">
          <h3>Data Analytics</h3>
          <p>
            Our data analytics platform processes and analyzes multispectral
            data to provide actionable insights. Farmers receive
            easy-to-understand reports that help them make informed decisions
            about their crops.
          </p>
        </div>

        <!-- Software and Tools Subsection -->
        <div class="technology-card">
          <h3>Software and Tools</h3>
          <p>
            SkyFarm Solutions utilizes cutting-edge software to deliver
            real-time data and reports through a user-friendly dashboard. Our
            platform offers a comprehensive overview of farm performance,
            including yield analysis, water consumption, and soil conditions.
          </p>
        </div>
      </div>
    </section>

    <!-- Our Team Section -->
    <!-- <section class="our-team">
      <h2>Our Team</h2>
      <div class="team-member">
        <h3>CEO & Founder</h3>
        <p>
          Profile of the founder, including their background in GIS and Remote
          Sensing, and vision for the company.
        </p>
      </div>
      <div class="team-member">
        <h3>Data Analyst</h3>
        <p>
          Brief bio emphasizing their expertise in multispectral data analysis.
        </p>
      </div>
      <div class="team-member">
        <h3>Software Developer</h3>
        <p>
          Overview of their role in developing user-friendly digital platforms.
        </p>
      </div>
      <div class="team-member">
        <h3>Operations Manager</h3>
        <p>
          Description of their responsibilities in overseeing daily operations.
        </p>
      </div>
      <div class="team-member">
        <h3>Accountant</h3>
        <p>Highlighting their role in financial management.</p>
      </div>
      <div class="team-member">
        <h3>Future Team Members</h3>
        <p>
          As the company grows, an agronomist and additional specialists will
          join the team.
        </p>
      </div>
    </section> -->

    <!-- Our Values Section -->
    <section class="our-values">
      <h2>Our Values and Impact</h2>
      <p>
        Innovation, Sustainability, Integrity, and Customer Focus are at the
        heart of everything we do at SkyFarm Solutions.
      </p>
      <p>
        Information on the company’s commitment to improving farmer livelihoods,
        supporting local economies, and promoting sustainable farming practices.
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: "AboutComponent",
};
</script>

<style scoped>
.about {
  padding: 20px;
}

.our-story {
  background-image: linear-gradient(rgba(0, 0, 0, 0.331), rgba(0, 0, 0, 0.348)),
    url("@/assets/Logo/about2.jpg");
  background-size: cover;
  background-position: center;
  color: white;
  padding: 50px;
  text-align: center;
}

.our-story .content {
  max-width: 800px;
  margin: 0 auto;
}

.our-team,
.our-values,
.our-impact {
  margin-top: 40px;
}

.team-member {
  margin-bottom: 20px;
}

h1,
h2,
h3 {
  margin-bottom: 10px;
}

p {
  margin-bottom: 20px;
}

.technology {
  padding: 20px;
  background-color: #f9f9f9;
}

.technology-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.technology-card {
  flex: 1 1 calc(33.333% - 20px); /* Adjusts to make three cards fit in a row with a 20px gap */
  background-color: white;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

@media (max-width: 768px) {
  .technology-card {
    flex: 1 1 100%; /* Stacks the cards on smaller screens */
  }
}
</style>
