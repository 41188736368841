<template>
  <div class="services">
    <!-- Overview Section -->
    <section class="services-overview">
      <h1>Services</h1>
      <p>
        At SkyFarm Solutions, we offer a range of precision agriculture services
        designed to meet the unique needs of small-scale and medium-scale
        farmers. Explore our services below to learn how we can help you
        optimize your farming practices.
      </p>
    </section>

    <!-- Individual Services Section -->
    <section class="individual-services">
      <!-- Crop Health Monitoring -->
      <div class="service-card">
        <img
          src="@/assets/Logo/crop-health-monitoring.jpg"
          alt="Crop Health Monitoring"
          class="service-image"
        />
        <h2>Crop Health Monitoring</h2>
        <p>
          Using advanced drones equipped with multispectral cameras, we provide
          detailed insights into crop health. Our technology helps identify
          areas of stress early, allowing for timely interventions.
        </p>
      </div>

      <!-- Soil Analysis -->
      <div class="service-card">
        <img
          src="@/assets/Logo/soil-analysis.jpg"
          alt="Soil Analysis"
          class="service-image"
        />
        <h2>Soil Analysis</h2>
        <p>
          Soil health is crucial for crop productivity. Our comprehensive soil
          analysis provides farmers with information on soil composition,
          nutrient levels, and potential areas for improvement.
        </p>
      </div>

      <!-- Irrigation Management -->
      <div class="service-card">
        <img
          src="@/assets/Logo/irrigation-management.jpg"
          alt="Irrigation Management"
          class="service-image"
        />
        <h2>Irrigation Management</h2>
        <p>
          Optimize water use with our precision irrigation management services.
          We help farmers implement efficient irrigation strategies based on
          real-time data, conserving water and reducing costs.
        </p>
      </div>

      <!-- Targeted Fertilizer Application -->
      <div class="service-card">
        <img
          src="@/assets/Logo/fertilizer-application.jpg"
          alt="Targeted Fertilizer Application"
          class="service-image"
        />
        <h2>Targeted Fertilizer Application</h2>
        <p>
          Our sprayer drones apply fertilizers precisely where needed, reducing
          waste and promoting healthier crops. This targeted approach ensures
          that each plant receives the right amount of nutrients.
        </p>
      </div>

      <!-- Custom Solutions -->
      <div class="service-card">
        <img
          src="@/assets/Logo/custom-solutions.jpg"
          alt="Custom Solutions"
          class="service-image"
        />
        <h2>Custom Solutions</h2>
        <p>
          We understand that every farm is unique. Contact us to discuss
          customized solutions tailored to your specific farming needs.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ServicesComponent",
};
</script>

<style scoped>
.services {
  padding: 20px;
  background-color: #f5f5f5;
}

.services-overview {
  margin-bottom: 40px;
  text-align: center;
}

.services-overview h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.services-overview p {
  font-size: 1.2em;
  color: #333;
}

.individual-services {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
  padding: 0 20px; /* Adds padding on the sides */
}

.service-card {
  flex: 1 1 calc(23% - 20px); /* Adjusted for four cards per row with padding */
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.service-card h2 {
  margin-bottom: 10px;
}

.service-card p {
  color: #666;
  line-height: 1.5;
}

.service-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 15px;
}

.technology {
  background-color: #e6f5ea;
  padding: 20px;
  margin-bottom: 40px;
  border-radius: 8px;
}

.technology h2 {
  color: #04ae34;
  text-align: center;
  margin-bottom: 20px;
}

.technology-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.technology-card {
  flex: 1 1 calc(33% - 20px);
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.technology-card h3 {
  color: #04ae34;
  margin-bottom: 10px;
}

.technology-card p {
  color: #333;
}

.case-studies {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
}

.case-studies h2 {
  text-align: center;
  margin-bottom: 20px;
}

.case-studies p {
  color: #666;
  line-height: 1.5;
  text-align: center;
}

@media (max-width: 768px) {
  .service-card,
  .technology-card {
    flex: 1 1 100%;
  }
}
</style>
