<template>
  <div class="contact">
    <!-- Contact Page Header -->
    <h1>Contact Us</h1>
    <h4>Connect with Us – We're here to help you thrive!</h4>

    <!-- Contact Card Section -->
    <div class="contact-card">
      <!-- Contact Form Section -->
      <div class="contact-form-section">
        <h2>Get in Touch</h2>
        <form @submit.prevent="sendEmail" class="contact-form">
          <div class="form-group">
            <label for="name">Name</label>
            <input
              v-model="form.name"
              type="text"
              id="name"
              placeholder="Your Name"
              required
            />
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input
              v-model="form.email"
              type="email"
              id="email"
              placeholder="Your Email"
              required
            />
          </div>
          <div class="form-group">
            <label for="phone">Phone Number</label>
            <input
              v-model="form.phone"
              type="tel"
              id="phone"
              placeholder="Your Phone Number"
              required
            />
          </div>
          <div class="form-group">
            <label for="inquiry-type">Inquiry Type</label>
            <select v-model="form.inquiryType" id="inquiry-type" required>
              <option value="">Select an Inquiry Type</option>
              <option value="general">General</option>
              <option value="services">Services</option>
              <option value="support">Support</option>
              <option value="partnerships">Partnerships</option>
            </select>
          </div>
          <div class="form-group">
            <label for="message">Message</label>
            <textarea
              v-model="form.message"
              id="message"
              rows="5"
              placeholder="Your Message"
              required
            ></textarea>
          </div>
          <button type="submit" class="submit-button">Send Message</button>
        </form>
      </div>

      <!-- Location and Map Section -->
      <div class="location-map-section">
        <h2>Our Location</h2>
        <p>
          SkyFarm Solutions is based in the Eastern Cape, South Africa, with
          outreach extending into the Western Cape for more opportunities. While
          we don’t have an established office space yet, we operate remotely and
          on-site to serve our clients better.
        </p>
        <div class="map">
          <!-- Replace with actual map embedding code if available -->
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890076.728008735!2d22.93750635011615!3d-32.18247659386578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e1b289b4f9208f1%3A0x78e4e3d8b6a8824d!2sEastern%20Cape%2C%20South%20Africa!5e0!3m2!1sen!2sza!4v1600000000000!5m2!1sen!2sza"
            width="100%"
            height="350"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>

    <!-- Other Contact Information Section -->
    <section class="other-contact-info">
      <h2>Other Contact Information</h2>
      <p><strong>Phone:</strong> +27 79 172 4829</p>
      <p><strong>Email:</strong> skyyfarmsolutions@gmail.com</p>
    </section>
  </div>
</template>

<script>
import emailjs from "emailjs-com";

export default {
  name: "ContactComponent",
  data() {
    return {
      form: {
        name: "",
        email: "",
        phone: "",
        inquiryType: "",
        message: "",
      },
    };
  },
  methods: {
    sendEmail() {
      const templateParams = {
        name: this.form.name,
        email: this.form.email,
        phone: this.form.phone,
        inquiryType: this.form.inquiryType,
        message: this.form.message,
      };
      console.log("EmailJS Service ID:", process.env.SERVICE_ID);
      console.log("EmailJS Template ID:", process.env.TEMPLATE_ID);
      console.log("EmailJS User ID:", process.env.USER_ID);
      emailjs
        .send(
          process.env.VUE_APP_SERVICE_ID,
          process.env.VUE_APP_TEMPLATE_ID,
          templateParams,
          process.env.VUE_APP_USER_ID
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            alert("Message sent successfully!");
            this.resetForm();
          },
          (error) => {
            console.error("FAILED...", error);
            alert("There was an error sending the message.");
          }
        );
    },
    resetForm() {
      this.form = {
        name: "",
        email: "",
        phone: "",
        inquiryType: "",
        message: "",
      };
    },
  },
};
</script>

<style scoped>
.contact {
  padding: 20px;
  background-color: white;
}

.contact h1,
.contact h2 {
  text-align: center;
}

.contact-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 40px;
}

.contact-form-section {
  flex: 1;
  max-width: 45%;
  background-color: #eaeaea; /* Light grey background */
  padding: 20px;
  /* border-radius: 8px; */
}

.location-map-section {
  flex: 1;
  max-width: 45%;
  padding: 20px;
  background-color: #ffffff; /* White background for the map */
  border-radius: 8px;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  display: block; /* Ensure labels are aligned to the left */
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #04ae34;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #03992e;
}

.map {
  text-align: center;
  margin-top: 20px;
}

.other-contact-info {
  text-align: center;
  margin-bottom: 40px;
}

.social-media a {
  color: #04ae34;
  text-decoration: none;
  margin: 0 5px;
}

.social-media a:hover {
  text-decoration: underline;
}

.faq-section {
  margin-bottom: 40px;
  text-align: center;
}

.faq-item {
  margin-top: 20px;
}

.faq-item h3 {
  font-size: 1.2em;
}

.faq-item p {
  margin-top: 5px;
}

@media (max-width: 768px) {
  .contact-card {
    flex-direction: column;
  }
  .contact-form-section,
  .location-map-section {
    max-width: 100%;
  }
}
</style>
