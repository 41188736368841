import { createRouter, createWebHistory } from "vue-router";
// import Layout from "../components/Layout/Layout.vue"; // Import layout component
import Home from "../components/Home/Home.vue";
import About from "../components/About/About.vue";
import Services from "../components/Services/Services.vue";
import Contact from "../components/Contact/Contact.vue";

const routes = [
  {
    path: "/",
    component: Home,
    // children: [
    //   { path: "", component: Home },
    //   { path: "about", component: About },
    //   { path: "services", component: Services },
    //   { path: "contact", component: Contact },
    // ],
  },
  {
    path: "/About",
    component: About,
  },
  {
    path: "/Services",
    component: Services,
  },
  {
    path: "/Contact",
    component: Contact,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
